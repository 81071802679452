<template>
  <section class="card-carousel">
    <cdr-text
      class="card-carousel__heading"
      tag="h2"
    >
      {{ heading }}
    </cdr-text>
    <thumbnail-nav
      v-if="isSmall"
      :slides="slides"
      li-class="card-carousel__item"
    >
      <template #slide="props">
        <benefit-card-component v-bind="props" />
      </template>
    </thumbnail-nav>
    <div
      v-else
      class="card-group__track"
    >
      <benefit-card-component
        v-for="(props, index) in slides"
        v-bind="props"
        :key="index"
      />
    </div>
  </section>
</template>

<script>
import { CdrText } from '@rei/cedar';
import BenefitCardComponent from './BenefitCardComponent.vue';
import ThumbnailNav from '../../../common/components/thumbnail-nav/ThumbnailNav.vue';

export default {
  name: 'CardCarousel',
  components: {
    BenefitCardComponent,
    CdrText,
    ThumbnailNav,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: true,
    },
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">

.card-carousel {
  max-width: 100vw;
  // allow scroll off left & right side with full bleed
  margin-left: -$cdr-space-one-x;
  margin-right: -$cdr-space-one-x;

  @include cdr-md-mq-up {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: $cdr-space-one-x;
  }

  @include cdr-sm-mq-up {
    .thumbnail-nav__track {
      justify-content: center;
    }

    .benefit-card {
      max-width: 36.8rem;
    }
  }
}

.card-carousel__heading {
  @include cdr-text-utility-serif-600;
  text-align: center;
  color: $cdr-color-text-brand;
  width: 80vw;
  max-width: 600px;
  margin: 0 auto $cdr-space-one-and-a-half-x;
  @include cdr-md-mq-up {
    @include cdr-text-heading-display-900;
  }
}

.card-carousel__item:last-child {
  margin-right: $cdr-space-one-x;
  @include cdr-md-mq-up {
    margin-right: 0;
  }
}

.card-group__track {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: $cdr-space-one-and-a-half-x;
  justify-content: center;
  @include cdr-md-mq-up {
    grid-column-gap: $cdr-space-two-x;
  }
}
</style>
